import { IRequestOptions, IResponseResult } from 'models/api.model'
import { TAnswers } from 'models/common.model'
import {
  IBindUserResponseRaw,
  IGetUserStatusResponseRaw,
} from 'models/user.model'
import { APP_NAME, RequestMethod } from 'root-constants/common'
import { ApiService } from 'services/api.service'

export class UserApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  bindUser(payload: {
    token: string
    uuid: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        oauth_token: payload.token,
        uuid: payload.uuid,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>('v1/flow/bind', options)
  }

  getUUID(cohort: string, appId: string): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        app_id: appId,
        app_name: APP_NAME,
      },
    }

    return this.api.makeRequest<string>('v1/flow/uuid', options)
  }

  sendUserInfo(payload: {
    uuid: string
    email: string
    answers: TAnswers
    fbp: string
    fbc: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid: payload.uuid,
        email: payload.email,
        onboarding: payload.answers,
        fbp: payload.fbp,
        fbc: payload.fbc,
      },
    }

    return this.api.makeRequest<never>(`v1/flow/userinfo`, options)
  }

  getUserUUIDAndSecret(
    token: string,
  ): Promise<IResponseResult<{ secret: string; uuid: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { token },
    }

    return this.api.makeRequest<{ secret: string; uuid: string }>(
      `v1/manage/login`,
      options,
    )
  }

  getUserStatus(
    uuid: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { uuid },
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      `v1/flow/status`,
      options,
    )
  }
}
