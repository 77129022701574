import { IAppState } from 'models/store.model'
import { ValidationText } from 'root-constants/common'

export const selectEmailErrorMessage = (
  state: IAppState,
): ValidationText | null => state.account.emailErrorMessage

export const selectPasswordErrorMessage = (
  state: IAppState,
): ValidationText | null => state.account.passwordErrorMessage
