import { ConnectedRouter } from 'connected-react-router'
import { setViewportFullHeight } from 'helpers/setViewportFullHeight'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useInitGoogleAnalyticsTracker } from 'hooks/useInitGoogleAnalyticsTracker'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetOptimizeVariantId } from 'hooks/useSetOptimizeVariantId'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { routeHistory } from 'route-history'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import { Spinner } from './Spinner'

const SubscribeFlow = lazy(() => import('components/SubscribeFlow'))

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  useInitAppConfig()
  useInitGoogleAnalyticsTracker()
  useScrollFix()
  useSetOptimizeVariantId()

  return (
    <ConnectedRouter history={routeHistory}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route component={SubscribeFlow} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  )
}
