import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { EventLoggerInstanceName, LoginMethod } from 'root-constants/common'
import { TProductId } from 'models/variant.model'
import { PaymentMethod } from 'modules/payment/constants'
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'

const enum ScreenName {
  PURCHASE = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  QUESTION_COMPLETED = 'question_page_completed',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  EMAIL_PAGE_ERROR = 'email_page_error',
  PAYMENT_METHOD_PAGE_SHOW = 'payment_method_page_show',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  SALE_SCREEN_SHOW = 'plans_page_show',
  CANCEL_OFFER_SCREEN_SHOW = 'cancel_offer_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  CONTACT_US = 'contact_us_tap',
  NEED_HELP = 'need_help_tap',
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )
    this.loggers = new Map(entriesArr)
    this.notifyInitFinished()
  }

  logSessionStarted = ({
    optimizeVariantId,
    optimizeExperimentId,
    optimizeSegmentName,
  }: {
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
  }): void => {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      device_type: getMobileOperatingSystem(),
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logQuestion({
    question,
    answers,
    pageName,
    level,
  }: TQuestionPageParams): void {
    const event = Events.QUESTION_COMPLETED

    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
      screen_name: pageName,
      ...(level && { level }),
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logEmailPageError(eventProperty: { error: string }): void {
    const event = Events.EMAIL_PAGE_ERROR
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    pageName,
    isCancelOfferApplied = false,
  }: {
    productIds: string[]
    pageName: string
    isCancelOfferApplied?: boolean
  }): void {
    const event = isCancelOfferApplied
      ? Events.CANCEL_OFFER_SCREEN_SHOW
      : Events.SALE_SCREEN_SHOW
    const eventProperty = {
      product_id: productIds.join(','),
      screen_name: pageName,
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  logTermsOfUseClicked(): void {
    const event = Events.TERMS_OF_USE
    this.logEventOrPushToQueue(event)
  }

  logPrivacyPolicyClicked(): void {
    const event = Events.PRIVACY_POLICY
    this.logEventOrPushToQueue(event)
  }

  logContactUsClicked(): void {
    const event = Events.CONTACT_US
    this.logEventOrPushToQueue(event)
  }

  logNeedHelpClicked(): void {
    const event = Events.NEED_HELP
    this.logEventOrPushToQueue(event)
  }

  // Account Page events
  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_SHOW
    this.logEventOrPushToQueue(event)
  }

  logLoginMethodSelected(eventProperty: { method: LoginMethod }): void {
    const event = Events.LOGIN_METHOD_SELECTED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    const event = Events.ACCOUNT_CREATED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue(event)
  }

  logDownloadClicked(): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    this.logEventOrPushToQueue(event)
  }

  // Payment
  logPaymentMethodPageShown(): void {
    const event = Events.PAYMENT_METHOD_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logPaymentMethodSelected({
    paymentMethod,
  }: {
    paymentMethod: PaymentMethod
  }): void {
    const event = Events.PAYMENT_METHOD_SELECTED
    const eventProperty = {
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // SubscriptionsWrapper Page events
  logPurchaseShown({
    productId,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    isCancelOfferApplied,
  }: {
    productId: TProductId
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    isCancelOfferApplied: boolean
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.PURCHASE,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStarted({
    productId,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    isCancelOfferApplied,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency?: string }
    paymentMethod: PaymentMethod
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    isCancelOfferApplied: boolean
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.PURCHASE,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseCompleted({
    productId,
    priceDetails: { price, trial = false, currency = 'USD' },
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    paymentMethod,
    discountApplied,
    transactionId,
    isCancelOfferApplied,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency?: string }
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    paymentMethod?: PaymentMethod
    discountApplied?: string
    transactionId?: string
    isCancelOfferApplied: boolean
  }): void {
    const event = Events.PURCHASE_COMPLETED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.PURCHASE,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
      ...(transactionId && { transaction_id: transactionId }),
      ...(discountApplied && { discount_applied: discountApplied }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseFailed({
    productId,
    priceDetails: { price, trial = false, currency = 'USD' },
    error: { description, type, code },
    paymentMethod,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    isCancelOfferApplied,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency?: string }
    error: { type: string; description?: string; code?: string }
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
    paymentMethod?: PaymentMethod
    isCancelOfferApplied: boolean
  }): void {
    const event = Events.PURCHASE_FAILED
    const eventProperty = {
      trial,
      price,
      currency,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.PURCHASE,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      optimize_experiment_id: optimizeExperimentId,
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
  ): void {
    if (this.loggers?.size) {
      this.logEvent(event, eventProperty)
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent(event, eventProperty),
      )
      this.eventsQueue = []
    }
  }

  private logEvent(event: Events, eventProperty?: Record<string, any>): void {
    this.loggers?.forEach((logger) => logger.log(event, eventProperty))
  }
}

export const eventLogger = new EventLoggerService()
