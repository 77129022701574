import { StripeFieldName } from 'root-constants/common'

export const PAYMENT_FIELD_NAMES = {
  [StripeFieldName.NAME]: 'name',
  [StripeFieldName.NUMBER]: 'card number',
  [StripeFieldName.EXPIRY]: 'MM/YY',
  [StripeFieldName.CVC]: 'CVV/CVC',
}

export const EMPTY_FIELD_ERROR = `can't be blank`

export const DEFAULT_CARDHOLDER_NAME = ' '

export const DEFAULT_ERROR_DESCRIPTION =
  'Please check your payment information or contact your bank and try again, or try a new payment method.'

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const STRIPE_ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=block',
    },
  ],
}
