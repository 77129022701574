import { IRequestOptions, IResponseResult } from 'models/api.model'
import { APP_NAME, RequestMethod } from 'root-constants/common'
import { ApiService } from 'services/api.service'
import { IRawVariant } from 'models/variant.model'

export class VariantsApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getVariant({
    cohort,
  }: {
    cohort: string
  }): Promise<IResponseResult<IRawVariant>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { cohort },
    }

    return this.api.makeRequest<IRawVariant>(
      `v1/flow/${APP_NAME}/variants`,
      options,
    )
  }
}
