import { useEffect } from 'react'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'
import { PagePath } from 'root-constants/common'
import { useSelector } from 'react-redux'
import {
  selectCurrentVariantCohort,
  selectGoogleAnalyticsId,
  selectGoogleOptimizeId,
} from 'root-redux/selects/common'
import { selectLocation } from 'root-redux/selects/router'

export const useInitGoogleAnalyticsTracker = (): void => {
  const location = useSelector(selectLocation)
  const cohort = useSelector(selectCurrentVariantCohort)
  const googleOptimizeId = useSelector(selectGoogleOptimizeId)
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)

  useEffect(() => {
    if (googleAnalyticsId && googleOptimizeId) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
      script.async = true
      document.head.appendChild(script)

      window.gtag('config', googleAnalyticsId, {
        optimize_id: googleOptimizeId,
        send_page_view: false,
      })
    }
  }, [googleAnalyticsId, googleOptimizeId])

  useEffect(() => {
    const { pathname } = location
    const currentOnboardingVariant = cohort ? `/${cohort}` : ``

    if (window.location.href.includes(PagePath.LOCALHOST)) return

    const currentLocation = `${pathname}${currentOnboardingVariant}`
    googleAnalyticsLogger.logPageView(currentLocation)
  }, [location, cohort])
}
