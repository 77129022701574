export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 415,
  TABLET = 520,
}

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const TERMS_OF_USE_LINK = 'https://gismart.com/terms-of-services/'
export const PRIVACY_POLICY_LINK = 'https://gismart.com/privacy-policy/'
export const CONTACT_US_LINK = 'https://gismart.com/contacts/'

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum FirebaseSignInErrorCode {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled',
  INVALID_EMAIL = 'auth/invalid-email',
}

export const enum ValidationText {
  EMAIL_EXISTS = `Account with this email already exists`,
  INVALID_EMAIL = `Your email doesn't look valid. Please, check`,
  SHORT_PASSWORD = 'At least 8 characters',
  WRONG_CONFIRMATION_PASSWORD = `Confirmation password doesn't equal password`,
  WRONG_PASSWORD = 'The password you entered is incorrect',
  WRONG_EMAIL = 'The email you entered is incorrect',
}

export const enum Color {
  LIGHT = '#fff',
  DARK = '#000',
  ERROR = '#ed3b3b',
  LIME = '#d3f013',
  YELLOW = '#E7F809',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum UserAgent {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  OTHER = 'other',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  TUTORIAL = 'tutorial',
  SUBSCRIPTIONS = 'subscriptions',
}

export const DEFAULT_COHORT = 'djit3'
export const APP_NAME = 'djit'
export const CENTS_IN_DOLLAR = 100
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'CUSTOM_TOKEN'
export const DEFAULT_CURRENCY = 'usd'

export const DAYS_IN_PERIOD: { [key: string]: number } = {
  [TimeInterval.WEEK]: 7,
  [TimeInterval.MONTH]: 30,
}

export const QUIZ_CORRECT_ANSWERS: Record<string, string>[] = [
  { pageName: 'quiz_bpm_3', value: 'measurementOfTempo' },
  { pageName: 'quiz_jog_3', value: 'jog' },
  { pageName: 'quiz_flanger_3', value: 'effect' },
  { pageName: 'quiz_fader_3', value: 'fader' },
  { pageName: 'quiz_deck_3', value: 'playingRecords' },
]

export const QUIZ_2_CORRECT_ANSWERS: Record<string, string>[] = [
  { pageName: 'quiz_bpm_4', value: 'measurementOfTempo' },
  { pageName: 'quiz_jog_4', value: 'jog' },
  { pageName: 'quiz_flanger_4', value: 'effect' },
  { pageName: 'quiz_fader_4', value: 'fader' },
  { pageName: 'quiz_deck_4', value: 'playingRecords' },
]

export const enum PROGRESS_BAR_PAGE_STATUS {
  CURRENT = 'current-page',
  DEFAULT = 'default',
  WRONG_ANSWER = 'wrong-answer',
  CORRECT_ANSWER = 'correct-answer',
}

export const EXPERIENCE_OPTIONS_VALUES = {
  NULL: 'null',
  LITTLE: 'little',
  SOME: 'some',
}

export const PRACTICE_OPTIONS_VALUES = {
  ONCE_IN_A_WHILE: 'onceInAWhile',
  FEW_TIMES_A_WEEK: 'fewTimesAWeek',
  EVERY_DAY: 'everyDay',
  NOT_SURE: 'notSure',
}

export const BACKGROUND_OPTIONS_VALUES = {
  SINGING: 'singing',
  RAPPING: 'rapping',
  CHOIR_SINGING: 'choirSinging',
  PLAYING_INSTRUMENTS: 'playingInstruments',
  SONGWRITING: 'songwriting',
  MUSIC_PRODUCTION: 'musicProduction',
  OTHER: 'other',
  NONE: 'none',
}

export const USER_ANSWER_TO_MARKUP_MAP: { [key: string]: string } = {
  [BACKGROUND_OPTIONS_VALUES.SINGING]: 'singing',
  [BACKGROUND_OPTIONS_VALUES.RAPPING]: 'rapping',
  [BACKGROUND_OPTIONS_VALUES.CHOIR_SINGING]: 'choir singing',
  [BACKGROUND_OPTIONS_VALUES.PLAYING_INSTRUMENTS]: 'playing instruments',
  [BACKGROUND_OPTIONS_VALUES.SONGWRITING]: 'songwriting',
  [BACKGROUND_OPTIONS_VALUES.MUSIC_PRODUCTION]: 'music production',
  [BACKGROUND_OPTIONS_VALUES.OTHER]: 'music',
}
