import { AnyAction } from 'redux'
import {
  RESET_ERROR,
  SET_CONFIG,
  SET_ERROR,
  SET_VARIANT,
  START_FETCHING,
  STOP_FETCHING,
  RESET_ANSWERS,
  SET_ANSWERS,
  SET_IS_CANCEL_OFFER_APPLIED,
  SET_OPTIMIZE_VARIANT_ID,
  SET_KEYWORD_FROM_SEARCH_PARAMS,
  SET_IS_PAID_TRIAL_MODAL_SHOWN,
} from 'root-redux/actions/common'
import { IVariant } from 'models/variant.model'
import { IConfig } from 'models/config.model'
import { TAnswers } from 'models/common.model'

const initState: ICommonState = {
  isFetching: false,
  actionList: [],
  error: null,
  currentVariant: null,
  config: null,
  answers: null,
  isCancelOfferApplied: false,
  isPaidTrialModalShown: true,
  optimizeVariantId: 'no_test',
  optimizeSegmentName: 'no_test',
  keywordFromSearchParams: '',
}

export interface ICommonState {
  isFetching: boolean
  actionList: string[]
  error: any
  currentVariant: IVariant | null
  config: IConfig | null
  answers: TAnswers | null
  isCancelOfferApplied: boolean
  isPaidTrialModalShown: boolean
  optimizeVariantId: string
  optimizeSegmentName: string
  keywordFromSearchParams: string
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        currentVariant: {
          ...payload,
          subscriptions: payload.subscriptions.sort(
            (a, b) => a.showOrder - b.showOrder,
          ),
        },
      }
    }
    case SET_CONFIG: {
      return {
        ...state,
        config: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case RESET_ANSWERS: {
      return {
        ...state,
        answers: null,
      }
    }
    case SET_IS_CANCEL_OFFER_APPLIED: {
      return {
        ...state,
        isCancelOfferApplied: payload,
      }
    }
    case SET_IS_PAID_TRIAL_MODAL_SHOWN: {
      return {
        ...state,
        isPaidTrialModalShown: payload,
      }
    }
    case SET_OPTIMIZE_VARIANT_ID: {
      return {
        ...state,
        optimizeVariantId: payload,
        optimizeSegmentName:
          state.currentVariant?.optimizeMap?.[payload] || 'no_name',
      }
    }
    case SET_KEYWORD_FROM_SEARCH_PARAMS: {
      return {
        ...state,
        keywordFromSearchParams: payload,
      }
    }
    default:
      return state
  }
}
