import { TAnswers } from 'models/common.model'
import { IConfig, IFirebaseConfig } from 'models/config.model'
import { IAppState } from 'models/store.model'
import { IStep, ISubscription, IVariant } from 'models/variant.model'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariantCohort = (state: IAppState): string =>
  state.common.currentVariant?.cohort || ''
export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.currentVariant || null
export const selectCurrentVariantSubscriptions = (
  state: IAppState,
): ISubscription[] => state.common.currentVariant?.subscriptions || []
export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.currentVariant?.steps || []
export const selectConfig = (state: IAppState): IConfig | null =>
  state.common.config
export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.config?.amplitudeApiKey || null
export const selectGiaApiKey = (state: IAppState): string | null =>
  state.common.config?.giaApiKeys?.en || null
export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.config?.firebaseConfig || null
export const selectGoogleOptimizeId = (state: IAppState): string | null =>
  state.common.config?.googleOptimizeId || null
export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.config?.googleAnalyticsId || null
export const selectStripePublicKey = (state: IAppState): string | null =>
  state.common.config?.stripePublicKey || null
export const selectPayPalClientId = (state: IAppState): string | null =>
  state.common.config?.payPalClientId || null
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers
export const selectIsCancelOfferApplied = (state: IAppState): boolean =>
  state.common.isCancelOfferApplied
export const selectIsPaidTrialModalShown = (state: IAppState): boolean =>
  state.common.isPaidTrialModalShown
export const selectOptimizeExperimentId = (state: IAppState): string =>
  state.common.currentVariant?.optimizeExperimentId || ''
export const selectOptimizeVariantId = (state: IAppState): string =>
  state.common.optimizeVariantId
export const selectOptimizeSegmentName = (state: IAppState): string =>
  state.common.optimizeSegmentName
export const selectKeywordFromSearchParams = (state: IAppState): string =>
  state.common.keywordFromSearchParams
