import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getConfigAction } from 'root-redux/actions/common'

export const useInitAppConfig = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getConfigAction())
  }, [dispatch])
}
