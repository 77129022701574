import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { configApi, variantsApi } from 'api'
import { IVariant } from 'models/variant.model'
import { getVariantFromRawVariant } from 'helpers/getVariantFromRawVariant'
import { IConfig } from 'models/config.model'
import { getConfigFromConfigRaw } from 'helpers/getConfigFromConfigRaw'
import { AnyAction } from 'redux'
import { eventLogger } from 'services/eventLogger.service'
import { TAnswer, TQuestionStep } from 'models/common.model'

const MODULE_NAME = 'COMMON'

// actions types
export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const GET_VARIANT = `${MODULE_NAME}/GET_VARIANT`
export const SET_VARIANT = `${MODULE_NAME}/SET_VARIANT`
export const GET_CONFIG = `${MODULE_NAME}/GET_CONFIG`
export const SET_CONFIG = `${MODULE_NAME}/SET_CONFIG`
export const SET_ANSWERS = `${MODULE_NAME}/SET_ANSWERS`
export const RESET_ANSWERS = `${MODULE_NAME}/RESET_ANSWERS`
export const SET_IS_CANCEL_OFFER_APPLIED = `${MODULE_NAME}/SET_IS_CANCEL_OFFER_APPLIED`
export const SET_IS_PAID_TRIAL_MODAL_SHOWN = `${MODULE_NAME}/SET_IS_PAID_TRIAL_MODAL_SHOWN`
export const SET_OPTIMIZE_VARIANT_ID = `${MODULE_NAME}/SET_OPTIMIZE_VARIANT_ID`
export const SET_KEYWORD_FROM_SEARCH_PARAMS = `${MODULE_NAME}/SET_KEYWORD_FROM_SEARCH_PARAMS`

// actions handlers
export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function stopFetching(actionToStop: string): any {
  return (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function resetErrorAction(): IAction<never> {
  return {
    type: RESET_ERROR,
  }
}

export function setVariantAction(payload: IVariant): IAction<IVariant> {
  return {
    type: SET_VARIANT,
    payload,
  }
}

export function getVariantAction({
  cohort,
}: {
  cohort: string
}): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_VARIANT))

    const response = await variantsApi.getVariant({ cohort })

    if (response.success && response.data) {
      const variant = getVariantFromRawVariant(response.data.variant)

      dispatch(setVariantAction(variant))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setConfigAction(payload: IConfig): IAction<IConfig> {
  return {
    type: SET_CONFIG,
    payload,
  }
}

export function getConfigAction(): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_VARIANT))

    const response = await configApi.getConfig()

    if (response.success && response.data) {
      const config = getConfigFromConfigRaw(response.data.config)
      dispatch(setConfigAction(config))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setAnswersAction({
  question,
  answers,
  pageId,
  pageName,
  level,
}: {
  question: string
  answers: TAnswer
  pageId: TQuestionStep
  pageName: string
  level?: string
}): AnyAction {
  eventLogger.logQuestion({ question, answers, pageName, level })

  return {
    type: SET_ANSWERS,
    payload: { [pageId]: answers },
  }
}

export function resetAnswersAction(): AnyAction {
  return {
    type: RESET_ANSWERS,
  }
}

export function setIsCancelOfferAppliedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_CANCEL_OFFER_APPLIED,
    payload,
  }
}

export function setIsPaidTrialModalShownAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PAID_TRIAL_MODAL_SHOWN,
    payload,
  }
}

export function setOptimizeVariantIdAction(id: string): IAction<string> {
  return {
    type: SET_OPTIMIZE_VARIANT_ID,
    payload: id,
  }
}

export function setKeywordFormSearchParams(value: string): IAction<string> {
  return {
    type: SET_KEYWORD_FROM_SEARCH_PARAMS,
    payload: value,
  }
}
