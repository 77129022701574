import { TimeInterval } from 'root-constants/common'

export function createProductId({
  periodName,
  periodQuantity,
  price,
}: {
  periodName: TimeInterval | null
  periodQuantity: number | null
  price: number
}): string {
  return `djit_${periodQuantity}_${periodName}_${price}`
}
