import { IConfig, IConfigRaw } from 'models/config.model'

export const getConfigFromConfigRaw = (config: IConfigRaw): IConfig => ({
  applicationName: config.application_name,
  amplitudeApiKey: config.amplitude_api_key,
  firebaseConfig: config.firebase_config,
  giaApiKeys: config.gia_api_keys,
  googleAnalyticsId: config.google_analytics_id,
  googleOptimizeId: config.google_optimize_id,
  stripePublicKey: config.stripe_public_key,
  payPalClientId: config.paypal_client_id,
})
