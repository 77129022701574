/* eslint-disable max-lines */
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'
import { createProductId } from 'helpers/createProductId'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription, TPageId } from 'models/variant.model'
import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentMethod,
} from 'modules/payment/constants'
import { getRedirectUrl } from 'modules/payment/helpers'
import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  selectIsCancelOfferApplied,
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { logFailedPayment } from '../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../helpers/logSuccessfulPayment'
import {
  selectPaymentClientSecret,
  selectSubscriptionCurrentPrice,
  selectSubscriptionId,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionPriceId,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectSubscriptionTrialPriceId,
  selectTrialPeriodDays,
} from './selects'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<any> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setTrialPeriodDaysAction = (payload: number): IAction<number> => ({
  type: SET_TRIAL_PERIOD_DAYS,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const purchaseAction = ({
  stripe,
  paymentPageId,
  card,
  name,
  createPaymentResFromDigitalWallet,
}: {
  stripe: Stripe
  paymentPageId: TPageId
  card?: StripeCardNumberElement
  name?: string
  createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const priceId = selectSubscriptionPriceId(state)
  const trialPriceId = selectSubscriptionTrialPriceId(state)
  const uuid = selectUUID(state)
  const currentPrice = selectSubscriptionCurrentPrice(state)
  const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const optimizeVariantId = selectOptimizeVariantId(state)
  const optimizeExperimentId = selectOptimizeExperimentId(state)
  const optimizeSegmentName = selectOptimizeSegmentName(state)
  const isCancelOfferApplied = selectIsCancelOfferApplied(state)

  if (!priceId || !currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  dispatch(startFetching(PURCHASE))

  const productId = createProductId({
    periodName,
    periodQuantity,
    price: currentPrice,
  })

  const paymentMethod =
    createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

  eventLogger.logPaymentMethodSelected({
    paymentMethod: PaymentMethod.CREDIT_CARD,
  })
  eventLogger.logPurchaseStarted({
    productId,
    priceDetails: {
      price: currentPrice,
      trial: !!trialPeriodPrice,
    },
    paymentMethod,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    isCancelOfferApplied,
  })

  try {
    const createPaymentResponse =
      card && !createPaymentResFromDigitalWallet
        ? await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
          })
        : (createPaymentResFromDigitalWallet as PaymentMethodResult)

    if (!createPaymentResponse?.paymentMethod && createPaymentResponse?.error) {
      const {
        error: { type, code, message },
      } = createPaymentResponse

      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
        },
        error: {
          type,
          code,
          description: message,
        },
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        isCancelOfferApplied,
      })
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const createSubscriptionResponse = await paymentApi.createSubscription({
      uuid,
      priceId,
      trialPriceId,
      trialPeriodDays,
      paymentMethodId: createPaymentResponse.paymentMethod.id,
    })
    const isFreeTrial = !!trialPeriodDays && !trialPriceId

    if (
      !createSubscriptionResponse.success ||
      !createSubscriptionResponse.data
    ) {
      // It's a hack for fixing behavior after changing response
      // from WS BE after getting payment_method
      if (
        createSubscriptionResponse.data?.error?.startsWith(
          'creating new customer',
        )
      ) {
        let error = { type: createSubscriptionResponse.data?.error }

        try {
          error = JSON.parse(
            createSubscriptionResponse.data.error.split(
              'creating new customer: ',
            )[1],
          )
        } finally {
          logFailedPayment({
            productId,
            price: currentPrice,
            isTrialActive: !!trialPeriodPrice,
            paymentResponse: error,
            paymentMethod,
            optimizeExperimentId,
            optimizeVariantId,
            optimizeSegmentName,
            isCancelOfferApplied,
          })
          dispatch(
            setErrorAction({ type: createSubscriptionResponse.data?.error }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
        }
        return
      }

      if (isFreeTrial) {
        logFailedPayment({
          productId,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          paymentResponse: { type: createSubscriptionResponse.data?.error },
          paymentMethod,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          isCancelOfferApplied,
        })
        dispatch(
          setErrorAction({ type: createSubscriptionResponse.data?.error }),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      logFailedPayment({
        productId,
        price: currentPrice,
        isTrialActive: !!trialPeriodPrice,
        paymentResponse: { type: createSubscriptionResponse.data?.error },
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        isCancelOfferApplied,
      })
      dispatch(setErrorAction('Error: Something went wrong'))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    if (isFreeTrial) {
      logSuccessfulPayment({
        productId,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays:
          createSubscriptionResponse.data.subscription.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.subscription.subscription_id,
        uuid,
        periodName,
        periodQuantity,
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        isCancelOfferApplied,
      })
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
      return
    }

    setPaymentClientSecretAction(
      createSubscriptionResponse.data.subscription.client_secret,
    )
    setTrialPeriodDaysAction(
      createSubscriptionResponse.data.subscription.trial_period_days,
    )
    setSubscriptionIdAction(
      createSubscriptionResponse.data.subscription.subscription_id,
    )

    const cardPaymentResponseNoDiscount = await stripe.confirmCardPayment(
      createSubscriptionResponse.data.subscription.client_secret,
      {
        payment_method: createPaymentResponse.paymentMethod.id,
        save_payment_method: true,
        return_url: getRedirectUrl(paymentPageId),
      },
    )

    if (
      !cardPaymentResponseNoDiscount?.paymentIntent &&
      cardPaymentResponseNoDiscount?.error
    ) {
      logFailedPayment({
        productId,
        price: currentPrice,
        isTrialActive: !!trialPeriodPrice,
        paymentResponse: cardPaymentResponseNoDiscount.error,
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        isCancelOfferApplied,
      })
      dispatch(
        setErrorAction(
          getErrorActionPayload(cardPaymentResponseNoDiscount.error),
        ),
      )
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const { paymentIntent } = cardPaymentResponseNoDiscount

    if (paymentIntent.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
        },
        error: {
          type: 'requires_payment_method',
        },
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        isCancelOfferApplied,
      })
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

    if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
      dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
      dispatch(stopFetching(PURCHASE))
      return
    }

    logSuccessfulPayment({
      productId,
      price: currentPrice,
      trialPrice: trialPeriodPrice,
      trialPeriodDays:
        createSubscriptionResponse.data.subscription.trial_period_days,
      subscriptionId:
        createSubscriptionResponse.data.subscription.subscription_id,
      uuid,
      periodName,
      periodQuantity,
      paymentMethod,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      isCancelOfferApplied,
    })

    dispatch(stopFetching(PURCHASE))
    createPaymentResFromDigitalWallet?.complete('success')
  } catch (error: any) {
    dispatch(setErrorAction(error.toString()))
    dispatch(stopFetching(PURCHASE))
    createPaymentResFromDigitalWallet?.complete('fail')
  }
}

export const check3DSecure = (stripe: Stripe): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const priceId = selectSubscriptionPriceId(state)
  const currentPrice = selectSubscriptionCurrentPrice(state)
  const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const clientSecret = selectPaymentClientSecret(state)
  const trialPeriodDays = selectTrialPeriodDays(state)
  const subscriptionId = selectSubscriptionId(state)
  const uuid = selectUUID(state)
  const optimizeVariantId = selectOptimizeVariantId(state)
  const optimizeExperimentId = selectOptimizeExperimentId(state)
  const optimizeSegmentName = selectOptimizeSegmentName(state)
  const isCancelOfferApplied = selectIsCancelOfferApplied(state)

  if (!priceId || !currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  if (!clientSecret) {
    console.error('Error: client secret is needed')
    return
  }

  dispatch(startFetching(CHECK_3D_SECURE))

  const productId = createProductId({
    periodName,
    periodQuantity,
    price: currentPrice,
  })

  const response = await stripe.retrievePaymentIntent(clientSecret)

  if (response.paymentIntent?.status === 'succeeded') {
    logSuccessfulPayment({
      price: currentPrice,
      productId,
      trialPrice: trialPeriodPrice,
      trialPeriodDays,
      subscriptionId,
      uuid,
      periodName,
      periodQuantity,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      isCancelOfferApplied,
    })

    dispatch(reset3DSecureIframeUrlAction())
    return
  }

  if (response.paymentIntent?.status === 'requires_payment_method') {
    eventLogger.logPurchaseFailed({
      productId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
      },
      error: {
        type: 'requires_payment_method',
      },
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      isCancelOfferApplied,
    })
    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Payment failed'))
    dispatch(stopFetching(CHECK_3D_SECURE))
    return
  }

  if (response.error) {
    const {
      error: { type, code, message },
    } = response

    eventLogger.logPurchaseFailed({
      productId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
      },
      error: {
        type,
        code,
        description: message,
      },
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      isCancelOfferApplied,
    })
    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction(message || type))
    dispatch(stopFetching(CHECK_3D_SECURE))
    return
  }

  dispatch(reset3DSecureIframeUrlAction())
  dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
  dispatch(stopFetching(CHECK_3D_SECURE))
}
