import { IUserStatus } from 'models/user.model'
import { AnyAction } from 'redux'
import { SET_SECRET, SET_STATUS, SET_UUID } from '../actions/user'

export interface IUserState {
  uuid: string
  secret: string
  status: IUserStatus | null
}

const initState: IUserState = {
  uuid: '',
  secret: '',
  status: null,
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_SECRET: {
      return {
        ...state,
        secret: payload,
      }
    }
    case SET_STATUS: {
      return {
        ...state,
        status: payload,
      }
    }
    default:
      return state
  }
}
